"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RowProgressReporter = void 0;
class RowProgressReporter {
    constructor(progressName, field = 'writtenRowCount') {
        this.progressName = progressName;
        this.field = field;
        this.counter = 0;
        this.timeoutHandle = null;
    }
    add(count) {
        this.counter += count;
        if (!this.progressName) {
            return;
        }
        if (this.timeoutHandle) {
            return;
        }
        this.timeoutHandle = setTimeout(() => {
            this.timeoutHandle = null;
            this.send();
        }, 1000);
    }
    finish() {
        if (!this.progressName) {
            return;
        }
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = null;
        }
        this.send();
    }
    send() {
        if (!this.progressName) {
            return;
        }
        process.send({
            msgtype: 'progress',
            progressName: this.progressName,
            [this.field]: this.counter,
        });
    }
}
exports.RowProgressReporter = RowProgressReporter;
