import { __awaiter } from "tslib";
import newQuery from '../query/newQuery';
export function canOpenByWeb(file, extensions) {
    if (!file)
        return false;
    const nameLower = file.toLowerCase();
    if (nameLower.endsWith('.sql'))
        return true;
    return false;
}
export function openWebFileCore(file, extensions) {
    return __awaiter(this, void 0, void 0, function* () {
        const nameLower = file.path.toLowerCase();
        if (nameLower.endsWith('.sql')) {
            const reader = new FileReader();
            reader.onload = function (e) {
                newQuery({
                    initialData: e.target.result,
                });
            };
            reader.readAsText(file);
        }
    });
}
